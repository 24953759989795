.waving-hand {
    display: inline-block;
    animation: wave 6s infinite;
    transform-origin: 70% 70%;
}

@keyframes wave {
    0%,
    100% { transform: rotate(0deg); }
    5% { transform: rotate(14deg); }
    8% { transform: rotate(-8deg); }
    11% { transform: rotate(12deg); }
    14% { transform: rotate(-6deg); }
    17% { transform: rotate(8deg); }
    20% { transform: rotate(-4deg); }
    23% { transform: rotate(0deg); }
}